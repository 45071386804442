#resume-button{
    border: none;   
}

.resume-modal-close{
    display: none;
}

.resume-modal-is-open {
    // height: 80vh;
    display: grid;
    z-index: 5;
    position: absolute;
    // grid-column-start: 1;
    // grid-column-end: 3;
    // grid-row-start: 1;
    // grid-row-end: 3;
    justify-self: center;
    margin-top: -8em;
    grid-template-rows: 1fr;
    grid-template-columns: 45% 45% 10%;
    @media (min-width: 300px) and (max-width: 899px) {
        margin-top: 0em;
        margin-right: 5vw;
    }
    @media (min-width: 900px) and (max-width: 1500px) {
        margin-top: -5em;
        margin-left: 20em;
    }
    @media (min-width: 1501px) {
        margin-top: -10em;
        margin-left: 20em;
    }
}

.resume-pdf {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
}

#resume-dl-link {
    text-decoration: none;
    color: black;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    align-self: end;
    z-index: 3;     
    margin-left: -1em;
    margin-bottom: .5em;
}

#resume-dl-link:hover{
    color: red;
}

#close-resume {
    grid-column-start: 3;
    grid-column-end: 3;
    justify-self: flex-end;
    grid-row-start: 1;
    z-index: 3;
    font-size: 1.5em;
    padding-right: .25em;
}

#close-resume:hover{
    color: red;
    cursor: pointer;
}
h1 {
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width*2;
  line-height: 1;
  @include media-breakpoint-down(md) {
    font-size: $h1-font-size/2.25;
  }
}

h2 {
  margin-bottom: map-get($spacers, 5);

  > small {
    font-size: $h3-font-size;
    //color: $text-muted;
  }

  @include media-breakpoint-down(sm) {
    font-size: $h2-font-size/1.5;
  }
}

h3 {
  margin-bottom: $grid-gutter-width;
}

h4 {
  margin-bottom: $grid-gutter-width/3;
}

h4, .h4, h5, .h5, h6, .h6 {
  font-weight: 400;
}

#hero-grid{
  @media (min-width: 300px) and (max-width: 899px) {
    margin-top: $grid-gutter-width - 300;
    margin-bottom: $grid-gutter-width;
    line-height: 1;
    display: grid;
    grid-template-columns: 1.5fr .25fr;
    #hero-grid-img{
      height: 30vh;
      width: auto;
    }
    #resume-button{
      grid-column-start: 2;
      grid-row-start: 2;
      grid-row-end: 2;
      align-self: flex-start;
      justify-self: center;
      padding: 0.5em;
      margin: 1em;
      background-color: #738F9B;
      color: white;
      font-weight: 300;
      border-radius: 0.5em;
      border: none;
    }
  }
  @media (min-width: 900px) and (max-width: 1500px) {
    margin-top: $grid-gutter-width - 150;
    grid-column-gap: 10px;
    margin-bottom: $grid-gutter-width;
    line-height: 1;
    display: grid;
    grid-template-columns: 1.5fr .25fr;
    #resume-button{
      grid-column-start: 2;
      grid-row-start: 2;
      grid-row-end: 2;
      justify-self: center;
      padding: 0.5em;
      margin-top: 3em;
      background-color: #738F9B;
      color: white;
      font-weight: 300;
      border-radius: 0.5em;
      border: none;
      width: 5em;
      height: 2.5em;
    }
  }
  @media (min-width: 1441px) and (max-width: 2600px) {
    margin-top: $grid-gutter-width - 500;
    grid-column-gap: 10px;
    margin-bottom: $grid-gutter-width;
    line-height: 1;
    display: grid;
    grid-template-columns: 1.5fr .25fr;
    #resume-button{
      grid-column-start: 2;
      grid-row-start: 2;
      grid-row-end: 2;
      justify-self: center;
      padding: 0.5em;
      margin-top: 3em;
      background-color: #738F9B;
      color: white;
      font-weight: 300;
      border-radius: 0.5em;
      border: none;
      width: 5em;
      height: 2.5em;
    }
  }
}

#hero-grid-img{
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.hero-col-1{
  // grid-row-start: 1;
  grid-column-start: 1;
}

.flex-wrap{
  grid-column-start: 1;
}

.mt-auto, .my-auto {
  margin-top: 40em;
}

#resume-button{
  grid-column-start: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  justify-self: center;
  padding: 0.5em;
  margin-top: 3em;
  background-color: #738F9B;
  color: white;
  font-weight: 300;
  border-radius: 0.5em;
  border: none;
  width: 5em;
  height: 2.5em;
}

#resume-button:hover{
  background-color: #bad1db;
}

.big-text {
  font-size: 24px;
  //font-weight: $font-weight-light;

  @include media-breakpoint-down(md) {
    font-size: 20px;
  }

  .item-list {
    font-size: $h4-font-size;
  }
}

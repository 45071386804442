@import "../../../styles/vars";
@import "~bootstrap/scss/mixins/breakpoints";

$nav-panel-width: 300px;

.navigation {
  width: 100%;
  position: fixed;
  z-index: $zindex-fixed;
  top: 0;
  transition: top .3s;

  &.hide {
    top: -200px;
  }
}

.side-nav {
  position: fixed;
  right: -$nav-panel-width;
  top: 0;
  width: $nav-panel-width;
  background: $black;
  height: 100vh;
  transition: right .3s;
  z-index: $zindex-fixed;

  display: flex;
  align-items: center;
  padding-left: $grid-gutter-width*2;

  @include media-breakpoint-up(sm) {
    right: -$nav-panel-width*2;
    width: $nav-panel-width*2;
  }

  &.active {
    right: 0;
  }

  .close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    transition: opacity .3s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: $white;
      border-radius: $grid-gutter-width;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

.nav-item {
  .nav-link {
    &.active {
      text-decoration: none;
    }
  }
}

.nav-link {
  color: $white;
  font-size: $h3-font-size;
  font-weight: 300;

  &:hover {
    color: $white;
  }
}

.nav-toggle {
  position: relative;
  display: inline-block;
  border: none;
  background: none;
  width: 40px;
  height: 10px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
  z-index: $zindex-fixed;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    width: 80px;
    height: 80px;
    position: absolute;
    left: -20px;
    top: -36px;
    z-index: $zindex-fixed + 10;
  }

  &:hover {
    + .nav-primary {
      right: 0;
    }
  }
}
